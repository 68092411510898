import { keyTypeConstants } from "../constants";
import Utils from "../utility";
import { sessionManager } from "../managers/sessionManager";

const { USER, AUTH0_ID_TOKEN, PLANTYPE, INVITE_TOKEN } = keyTypeConstants;

const getToken = () => sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);

const storeData = (data) => {
  if (data.accountCompletion) delete data.accountCompletion;
  Utils.storeToLocalStorage(USER, data);
};

const clearData = () => {
  localStorage.clear();
  sessionManager.removeDataFromCookies(AUTH0_ID_TOKEN, PLANTYPE);
  sessionManager.removeDataFromCookies(INVITE_TOKEN);
};
const clearLocalAndAuthtoken = () => {
  localStorage.clear();
  sessionManager.removeDataFromCookies(AUTH0_ID_TOKEN, PLANTYPE);
};

const token = getToken();

const getUserData = () => {
  if (token) return Utils.getDataFromLocalStorage(USER);
  localStorage.clear();
  return null;
};

const data = getUserData();
const userData = !!data ? data : {};

const initialState = {
  isLoggedIn: !!userData?.userId,
  userId: userData?.userId || "",
  name: userData?.name || "",
  firstName: userData?.firstName || "",
  lastName: userData?.lastName || "",
  companyName: userData?.companyName || "",
  email: userData?.email || "",
  profilePicture: userData?.profilePicture || "",
  accessToken: userData?.accessToken || "",
  tenantId: userData?.tenantId || "",
  privacyPolicy: userData?.privacyPolicy || "",
  termsAndConditions: userData?.termsAndConditions || "",
  email_verified: userData?.email_verified,
  accountCompletion: userData?.accountCompletion || { isLoading: true },
  adminTenantId: userData?.adminTenantId || "",
  invitedBy: userData?.invitedBy || [],
  role: userData?.role || "",
  // companyData: userData?.companyData || [],
  userSubscriptionData: userData?.userSubscriptionData || [],
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER":
      const { isLoggedIn, id_token, ...localData } = {
        ...state,
        ...action.payload,
      };
      storeData(localData);
      return { ...state, ...action.payload, isLoggedIn: true };
    case "UPDATE_ACC_COMPLETION":
      return {
        ...state,
        accountCompletion: { ...state.accountCompletion, ...action.payload },
      };
    case "REMOVE_USER":
      clearData();
      return {
        isLoggedIn: false,
        userId: "",
        name: "",
        firstName: "",
        companyName: "",
        lastName: "",
        email: "",
        profilePicture: "",
        accessToken: "",
        idToken: "",
        // companyData: [],
        userSubscriptionData: [],
        isKnowledgeBaseExist: false,
        isAppExist: false,
        isBotExist: false,
        isGoldenQuestionExist: false,
        isBotPerformanceExist: false,
        invitedBy: [],
      };
    case "LOGOUT_ON_INVITE_LOGIN":
      clearLocalAndAuthtoken();
      return {
        isLoggedIn: false,
        userId: "",
        name: "",
        firstName: "",
        companyName: "",
        lastName: "",
        email: "",
        profilePicture: "",
        accessToken: "",
        idToken: "",
        isKnowledgeBaseExist: false,
        isAppExist: false,
        // companyData: [],
        userSubscriptionData: [],
        isBotExist: false,
        isGoldenQuestionExist: false,
        isBotPerformanceExist: false,
        invitedBy: [],
      };
    default:
      return state;
  }
}
