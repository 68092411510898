import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { GET , POST },
  API_END_POINT: { GET_PLANS, GET_SUBSCRIPTION, INVOICE , CANCEL_SUBSCRITPION , UPGRADE_DEGRADE },
} = httpConstants;
const { AUTH0_ID_TOKEN } = keyTypeConstants;
export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class ContentService {
  baseUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;
  localUrl = "http://localhost:3010";

  getPlans = async (data) => {
    const query = !!data
      ? `${GET_PLANS}?${new URLSearchParams(data).toString()}`
      : GET_PLANS;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  getSubscription = async (data) => {
    const query = data
      ? `${GET_SUBSCRIPTION}?${new URLSearchParams(data).toString()}`
      : GET_SUBSCRIPTION;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getInvoice = async (data) => {
    const query = data
      ? `${INVOICE}?${new URLSearchParams(data).toString()}`
      : INVOICE;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
  cancelSubscription(request) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + CANCEL_SUBSCRITPION, request, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  upgradeAndDegrade(request) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + UPGRADE_DEGRADE, request, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
}